<template>
<!-- <v-app style="width:100%;">
  <v-container fluid> -->
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headersvehiculos"
            :items="vehiculos"
            :search="buscador"
            class="elevation-1 bordecolor"
            item-key="pkvehiculo"
            :expanded.sync="expanded"
            :show-expand="(expanded=='') ? false : true"
          >
            <template v-slot:top>
              <v-toolbar
                flat
                color="claro"
                dark
              >
                <v-toolbar-title >VEHÍCULOS</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="buscador"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  filled
                  dense
                  outlined
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn
                  color="white"
                  style="margin-top: 0px; margin-left: 15px;"
                  @click="DialogNuevoVehiculo=true"
                >
                  <h4 style="color:#000;">Nuevo vehículo</h4>
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:[`item.estado`]="{ item }">
              <!-- {{(item.fecbaja=='0000-00-00') ? 'ACTIVO' : 'BAJA'}} -->
              <v-chip
                :class="(Estado(item)) ? 'active-chip' : 'inactive-chip'"
                outlined
                label
              >
                {{(Estado(item)) ? 'ACTIVO' : 'BAJA'}}
              </v-chip>
            </template>
            <template v-slot:[`item.editar`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="background-color: #000; width: 120%; height: 60%; border-radius: 5px;"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="expandedIsActive"
                      small
                      dark
                      @click="showDetails = 'editvehi', expanded = [item]; editardescripcion=item.descripcion, pkvehiculo=item.pkvehiculo"
                    >
                      mdi-pencil
                    </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="
                      background-color: #000;
                      border-radius: 5px;
                      height: 33px;
                      width: 35px;
                    "
                    v-bind="attrs"
                    v-on="on"
                    dark
                    :disabled="expandedIsActive"
                    small
                    @click="
                      showDetails = 'pagarplandepagos',
                        expanded = [item], 
                        datosvehiculo=item,
                        AbrirDialogPlanDePagos()
                    "
                  >
                    mdi-cash-multiple
                  </v-icon>
                </template>
                <span>Plan de Pagos</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="margin-left: 17px; background-color: #000;border-radius: 5px; height: 33px; width: 35px;"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="expandedIsActive"
                    dark
                    small
                    @click="showDetails = 'pagardeudas', expanded = [item], datosvehiculo=item, AbrirDialogDeudas()"
                  >
                    mdi-currency-usd
                  </v-icon>
                </template>
                <span>Pagar deudas</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="margin-left: 17px; background-color: red;border-radius: 5px; height: 33px; width: 35px;"
                    v-bind="attrs"
                    :disabled="expandedIsActive"
                    dark
                    small
                    v-on="on"
                    @click="EliminarVehiculo(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Eliminar Vehiculo</span>
              </v-tooltip>
            </template>
            <template v-slot:[`expanded-item`]="{ headers}">
              <td :colspan="headers.length" style="padding:0px!important;">
                <v-container fluid>
                  <v-row no-gutters justify="center">
                    <v-card min-width="500" v-if="showDetails=='editvehi'" class="bordecolor">
                      <v-card-title class="headline">
                        <v-row justify="center" style="margin-top:5px;">
                            <v-icon
                            style="background-color: #BDBDBD; width: 30px; height: 30px;; border-radius: 5px; margin-right:10px;"
                            dark
                            >
                              mdi-pencil
                            </v-icon> 
                            <b>Editar vehículo</b>
                          </v-row>
                      </v-card-title>
                      <v-card-text>
                        <v-text-field
                          v-model="editardescripcion"
                          label="Descripcion"
                          filled
                          class="mt-6"
                          outlined
                          dense
                          :rules="[
                                      (v) =>
                                      !!v ||
                                      'Falta la descripcion',
                                  ]"
                          required
                        ></v-text-field>
                      </v-card-text>
                      <v-card-actions class="justify-center">
                      <v-btn dark
            color="error" @click="expanded=[]" class="mr-2">
                        cancelar
                      </v-btn>
                      <v-btn
                          color="claro"
                          dark
                          @click="EditarVehiculo()"
                      >
                          Confirmar
                      </v-btn>
                      </v-card-actions>
                    </v-card>
                    <v-card v-if="showDetails=='pagardeudas'" class="ancho_tabla">
                      <pagardeudas ref="formDeuda" :user="componentepagardeuda" @vaciarExpanded="vaciarExpanded"></pagardeudas>
                    </v-card>
                    <v-card class="ancho_tabla" v-if="showDetails=='pagarplandepagos'">
                      <pagarplandepagos ref="pagarplandepagosform" @vaciarExpanded="vaciarExpanded" :user="componentepagardeuda"></pagarplandepagos>
                    </v-card>
                  </v-row>
                </v-container>
              </td>
            </template>
            <template v-slot:no-data>
              <v-btn dark color="claro" @click="initialize">Cargar</v-btn>
            </template>
          </v-data-table>
        </v-col>
        <v-dialog
          v-model="DialogNuevoVehiculo"
          width="500"
          persistent
        >
          <v-card class="bordecolor">
          <v-form @submit.prevent="AgregarVehiculo" ref="formnuevovehiculo" v-model="validformnuevovehiculo">
            <v-card-title class="font-weight-bold">
              <v-row justify="center" style="margin-top:5px;">
              Agregar Nuevo Vehículo
              </v-row>
            </v-card-title>
            <v-card-text>
                <v-row class="mt-2">
                  <v-col cols="12">
                    <v-text-field
                      name="descripcion"
                      label="Descripcion"
                      placeholder="Nombre de referencia"
                      :rules="[
                                (v) =>
                                !!v ||
                                'Falta la descripcion',
                            ]"
                      required
                      color="#40A5DD"
                      outlined
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" style="margin-top:-15px;">
                    <v-text-field
                      label="Dominio"
                      name="dominio"
                      :rules="[
                                (v) =>
                                !!v ||
                                'Falta el dominio',
                            ]"
                      required
                      color="#40A5DD"
                      outlined
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" style="margin-top: -15px;">
                    <!-- <v-text-field
                      name="tipo"
                      label="Tipo"
                      :rules="[
                                (v) =>
                                !!v ||
                                'Falta el tipo',
                            ]"
                      required
                      color="#40A5DD"
                      outlined
                    ></v-text-field> -->

                    <p class="font-weight-bold">Tipo de vehículo:</p>
                    <v-radio-group
                      name="tipo"
                      row
                      :rules="[(v) => !!v || '']"
                      required
                    >
                      <v-radio label="Vehículo Particular" value="a"></v-radio>
                      <v-radio style="margin-right:10px;" label="Moto" value="m"></v-radio>
                      <v-radio style="margin-right:10px;" label="Vehiculo Público" value="p"></v-radio>
                    </v-radio-group>

                  </v-col>
                </v-row>           
                <v-checkbox 
                name="titular"
                v-model="checkboxtitular"
                :value="1"
                color="#40A5DD"
                >
                  <template v-slot:label>
                    <!-- <div> -->
                      Declaro ser titular / apoderado. Es necesario para solicitar determinados trámites.
                    <!-- </div> -->
                  </template>
                </v-checkbox>
            </v-card-text>
            <!-- <v-divider></v-divider> -->
            <v-card-actions class="justify-center">
              <v-btn @click="DialogNuevoVehiculo = false" color="error">
                cancelar
              </v-btn>
              <v-btn
                color="claro"
                dark
                type="submit"
                id="botonagregarvehiculo"
              >
                Guardar
              </v-btn>
              
            </v-card-actions>
          </v-form>    
          </v-card>
        </v-dialog>

        <v-dialog v-model="DialogEliminarVehiculo" persistent max-width="700">
          <v-card>
              <v-card-title class="headline justify-center">
              Seguro que desea eliminar el vehículo {{vehiculo}}?
              </v-card-title>
              <v-card-actions class="justify-center">
              <v-btn
                  text
                  @click="DialogEliminarVehiculo = false"
              >
                  Cancelar
              </v-btn>
              <v-btn
                  color="error"
                  @click="EliminarVehiculo()"
              >
                  Confirmar
              </v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>

      </v-row>
    <!-- </v-container> -->

    
<!-- </v-app> -->
</template>

<script>
import pagardeudas from "../Vehiculos/Pagar_deuda_vehiculo";
import pagarplandepagos from "../Vehiculos/Pagar_plandepagos_vehiculo";
export default {
  data: () => ({
    timeout:3000,
    msj:"",
    expanded:[],
    showDetails: "",
    headersvehiculos: [
      { text: "DESCRIPCION", align: "start", value: "descripcion" },
      { align: "start", value: "editar", width: 55},
      { text: "Dominio", value: "dominio", align: "start" },
      { text: "Tipo", value: "tipo", align: "start" },
      { text: "ESTADO", value: "estado", align:"center" },
      // { text: "EDITAR", value: "actions",sortable: false },
      { text: 'OPCIONES', value: 'actions',align: "right",width: 300, sortable: false},
    ],
    vehiculos:[],
    vehiculo:"",
    buscador:"",
    datevencimiento: new Date().toISOString().substr(0, 10),
    DialogNuevoVehiculo:false,
    descripcion:"",
    checkboxtitular:false,
    validformnuevovehiculo:true,
    DialogEliminarVehiculo: false,
    pkvehiculo: "",
    editardescripcion:"",
    datosvehiculo:"",
  }),

  components: {
    pagardeudas,
    pagarplandepagos
  },

  computed: {
    componentepagardeuda(){
      var tipo
      switch (this.datosvehiculo.tipo) {
        case "Vehiculo Particular":
          tipo= "auto"
          break;
        case "Moto":
          tipo= "moto"
          break;
        default:
          tipo= "publico"
          break;
      }
      return {vencimiento:this.datevencimiento, datosvehiculo:this.datosvehiculo, tipo:tipo}
    },
    expandedIsActive() {
      return this.expanded.length > 0;
    }
  },

  watch: {
    
    
  },

  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.ListadoVehiculos();
    },

    Estado(item){
      if(item.tipo=="Transporte Publico"){
        return item.fecbaja=='A'
      }else{
        return item.fecbaja=='0000-00-00'
      }
    },

    ListadoVehiculos(){
      var obj = this;
      this.$ajax
      .get(`${this.$apitramites}vehiculos/traerVehiculos`)
      .then(function(response) {
        console.log("Listado vehiculos", response);
        obj.vehiculos=response.data
      })
      .catch(function(error) {
        console.log(error)
        // obj.$swal.fire({
        //     title: "Error",
        //     text: "Sin datos cargados",
        //     icon: "error"
        //   });
      });
    },

     EliminarVehiculo(item){
      this.pkvehiculo=item.pkvehiculo;
      console.log(item);
      var obj = this;
      this.$swal.fire({
        title: "¿Desea eliminar este vehículo?",
        text: "¡Esta acción no se puede revertir!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No, cancelar!",
        confirmButtonText: "Sí, eliminar!",
      }).then((result) => {
        if (result.isConfirmed) {
          obj.$ajax
            .delete(`${obj.$apitramites}vehiculos/${obj.pkvehiculo}`)
            .then(function(response) {
              console.log("Eliminar vehiculo", response);
              obj.$swal.fire({
                title: "¡Eliminado!",
                text: "Vehículo eliminado con éxito",
                icon: "success"
              });
              obj.ListadoVehiculos();
            })
            .catch(function(error){
              console.error("Error al eliminar vehiculo", error);
              obj.$swal.fire({
                title: "Error",
                text: "Hubo un error al eliminar el vehículo",
                icon: "error"
              });
            });
        }
      });
    },

    EditarVehiculo(){
      var obj = this;
      this.$ajax
      .patch(`${this.$apitramites}Vehiculos/${this.pkvehiculo}`, {descripcion: this.editardescripcion})
      .then(function(response) {
        console.log("Editar Vehiculo", response);
        obj.$swal.fire({
                title: "¡Editado!",
                text: "Vehículo editado con éxito",
                icon: "success"
              });
        obj.expanded=[]
        obj.ListadoVehiculos();
      })
      .catch(function(error) {
        obj.$swal.fire({
                title: "Error",
                text: error,
                icon: "error"
              });
      });
    },

    laclase(item){
      if(item.condicontri != '2'){
        if(item.t_desvie == '1' && item.t_lugar == '1'){
          return {class:"green--text" , restringido: false};
        }else if( ((item.t_desvie == '1' && item.t_lugar =='0') || (item.t_desvie =='0' && item.t_lugar =='1')) && item.t_web == '1'){
          return {class:"orange--text" , restringido: false};
        }else{
          return {class:"red--text" , restringido: true};
        }
      }else{
          return {class:"red--text" , restringido: true};
      }
           
    },

    AgregarVehiculo(event){
      var obj = this;
      if (this.$refs.formnuevovehiculo.validate()) {
        console.log("hola", Object.fromEntries(new FormData(event.target)));
        let objetito = Object.fromEntries(new FormData(event.target));

        this.$ajax
        .post(`${this.$apitramites}vehiculos`, objetito)
        .then(function(response) {
          console.log("Nuevo vehiculo", response);
          obj.$swal.fire({
                title: "¡Agregado!",
                text: "Vehículo cargado con éxito",
                icon: "success"
              });
          obj.ListadoVehiculos();
          obj.DialogNuevoVehiculo=false;
          obj.$refs.formnuevovehiculo.reset();
        })
        .catch(function(error) {
          let mensaje = "Error, intente nuevamente"
            switch (error.response.data.message) {
              case "NOT_EXIST":
                  mensaje= "No se encuentra el vehículo"
                break;
              case "ROW_EXIST":
                  mensaje="El vehículo ya está asociado a su cuenta"
                break;
            }
            obj.$swal.fire({
              title: "Error",
              text: mensaje,
              icon: "error"
            });
        });
      }
    },

    // ColorEstado(fecha){
    //     var colores = {
    //         1: "success",
    //         2: "error",
    //     }
    //     return colores[fecha]
    // },

    AbrirDialogDeudas(){
      console.log("ref", this.$refs)
      console.log("ref2", this.$refs["formDeuda"])
      this.$refs['formDeuda'];
      // this.$refs['pagardeudasform'].ListadoCuotas();
      //this.$refs['pagardeudasform'].DialogDeudas = !this.$refs['pagardeudasform'].DialogDeudas;
    },
    AbrirDialogPlanDePagos() {
      this.$refs["pagarplandepagosform"];
    },
    vaciarExpanded(){
      this.expanded=[];
    }

  },
};
</script>
<style scoped>
.active-chip {
  color: green;
  border-color: green;
}

.inactive-chip {
  color: red;
  border-color: red;
}

.v-chip.v-chip--outlined.v-chip.v-chip.active-chip {
    background-color: #00800040;
}

.v-chip.v-chip--outlined.v-chip.v-chip.inactive-chip {
    background-color: rgba(255, 0, 0, 0.274);
}
</style>
