<template>
  <!-- <v-container fluid> -->
  <v-row>
    <principal
      ref="formprincipal"
      :datosescribano="checkescri"
      @check="CheckEscribano"
    ></principal>
    <v-col
      cols="12"
      style="margin-top: -25px"
      v-if="this.checkescri.estado == 2"
    >
      <!-- {{expanded}} -->
      <v-data-table
        :headers="headersescribanos"
        :items="escribanos"
        :search="buscador"
        class="elevation-1 bordecolor"
        item-key="pktramite"
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-toolbar flat color="claro" dark>
            <v-toolbar-title>TRÁMITES ESCRIBANO</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="buscador"
              append-icon="mdi-magnify"
              label="Buscar"
              filled
              dense
              outlined
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              color="white"
              style="margin-top: 0px; margin-left: 15px"
              @click="TraerValores(), (DialogNuevoTramite = true)"
            >
              <h4 style="color: #000">Nuevo Trámite</h4>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <span :class="Estado(item)">{{ item.nombre_estado }}</span>
          <v-btn
            @click="MotivoRechazo(item.motivoRechazo)"
            v-if="item.fkestado == 3"
            dense
            style="height: 28px; font-size: smaller; width: 75px"
            color="grey"
            dark
            class="ml-2"
            >Motivo</v-btn
          >
        </template>
        <!-- <template
              v-slot:[`item.nomenclatura_linda`]="{item}"
            >
              {{item.nomemclatura}}
            </template> -->
        <template v-slot:[`item.fecha_linda`]="{ item }">
          {{
            Fecha(
              item.fecha_ultima_modificacion
                ? item.fecha_ultima_modificacion
                : item.fecha_modificacion
            )
          }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style=" background-color: #40A5DD;border-radius: 5px; height: 33px; width: 35px;"
                    v-bind="attrs"
                    v-on="on"
                    dark
                    small
                    @click="showDetails = 'pagardeudas', expanded = [item], datosescribano=item, pkescribano=item.pktramite,  AbrirDialogDeudas()"
                  >
                    mdi-currency-usd
                  </v-icon>
                </template>
                <span>Pagar deudas</span>
              </v-tooltip> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="
                  background-color: #000;
                  border-radius: 5px;
                  height: 33px;
                  width: 35px;
                  margin-left: 17px;
                "
                v-bind="attrs"
                v-on="on"
                :disabled="expandedIsActive"
                dark
                small
                @click="
                  (showDetails = 'formuescri'),
                    (expanded = [item]),
                    (disabled = true),
                    (datostramite = item),
                    AbrirFormulario()
                "
              >
                mdi-eye-outline
              </v-icon>
            </template>
            <span>Ver Ficha</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.fkestado == 2">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="
                  background-color: #000;
                  border-radius: 5px;
                  height: 33px;
                  width: 35px;
                  margin-left: 17px;
                "
                v-bind="attrs"
                v-on="on"
                :disabled="expandedIsActive"
                dark
                small
                @click="CertificadoLiberado(item)"
              >
                mdi-card-account-details-outline
              </v-icon>
            </template>
            <span>Imprimir certificado</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.fkestado == 3 || item.fkestado == 5">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="
                  background-color: #000;
                  border-radius: 5px;
                  height: 33px;
                  width: 35px;
                  margin-left: 17px;
                "
                v-bind="attrs"
                v-on="on"
                :disabled="expandedIsActive"
                dark
                small
                @click="
                  (showDetails = 'formuescri'),
                    (expanded = [item]),
                    (disabled = false),
                    (datostramite = item),
                    AbrirFormulario()
                "
              >
                mdi-form-select
              </v-icon>
            </template>
            <span>Editar formulario</span>
          </v-tooltip>
        </template>
        <template v-slot:[`expanded-item`]="{ headers }">
          <td :colspan="headers.length" style="padding: 0px !important">
            <v-container>
              <v-row no-gutters justify="center">
                <v-card width="700" v-if="showDetails == 'formuescri'" flat>
                  <formulario
                    ref="formuescri"
                    @vaciarExpanded="vaciarExpanded"
                    :user="ComponenteEscribano"
                    :datostramite="datostramite"
                    @actualizarTabla="ListadoEscribanos"
                    @cerrar="CerrarExpand"
                  ></formulario>
                </v-card>
                <v-card class="ancho_tabla" v-if="showDetails == 'pagardeudas'">
                  <pagardeudas
                    ref="formDeuda"
                    @vaciarExpanded="vaciarExpanded"
                    :user="componentepagardeuda"
                  ></pagardeudas>
                </v-card>
              </v-row>
            </v-container>
          </td>
        </template>

        <template v-slot:no-data>
          <v-btn
            dark
            color="claro"
            @click="initialize"
            :disabled="loading"
            :loading="loading"
            >Cargar</v-btn
          >
        </template>
      </v-data-table>
    </v-col>
    <v-dialog v-model="DialogNuevoTramite" width="800" persistent>
      <v-card class="bordecolor" v-show="!alerta">
        <v-card-title class="font-weight-bold">
          <v-row justify="center" style="margin-top: 5px">
            Agregar Nuevo Trámite
          </v-row>
        </v-card-title>
        <v-card-text>
          <!-- {{nomenclatura}} -->
          <v-form
            ref="formnuevotramite"
            v-model="validformnuevotramite"
            lazy-validation
          >
            <v-radio-group v-model="tipo" row>
              <v-radio label="Nomenclatura Catastral" :value="1"></v-radio>
              <v-radio label="Partida" :value="2"></v-radio>
            </v-radio-group>
            <div v-if="tipo == 1">
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    v-model="nomenclatura.circunscripcion"
                    label="Circunscripción"
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="nomenclatura.seccion"
                    label="Sección"
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="nomenclatura.manzana"
                    label="Manzana"
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="nomenclatura.letra_manzana"
                    label="Letra"
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin-top: -15px">
                <v-col cols="3">
                  <v-text-field
                    v-model="nomenclatura.parcela"
                    label="Parcela"
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="nomenclatura.letra_parcela"
                    label="Letra"
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="nomenclatura.unidad_funcional"
                    label="Unidad func."
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="nomenclatura.chacra"
                    label="Chacra"
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin-top: -15px">
                <v-col cols="3">
                  <v-text-field
                    v-model="nomenclatura.letra_chacra"
                    label="Letra"
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="nomenclatura.quinta"
                    label="Quinta"
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="nomenclatura.fraccion"
                    label="Fracción"
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <div style="display: flex">
              <v-row>
                <v-col cols="6" v-if="tipo == 2">
                  <v-text-field
                    v-model="nomenclatura.partida"
                    label="Partida"
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col :cols="tipo == 2 ? '6' : '12'">
                  <v-autocomplete
                    v-model="nomenclatura.sellado"
                    :items="valores"
                    label="Valor del Sellado"
                    item-text="t_deta"
                    item-value="t_cod"
                    filled
                    dense
                    outlined
                  >
                    <template v-slot:item="data">
                      {{ data.item.t_deta }} {{ data.item.t_valor }} ({{
                        data.item.t_subtasa
                      }})
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </div>
            <!-- <div style="display:flex"> -->
            <!-- <h4>Valor del Sellado</h4> -->

            <!-- <v-col cols="6">
                <v-menu
                  v-model="menufecha"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Fecha"
                      prepend-icon="mdi-calendar"
                      filled
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="menufecha = false"
                  ></v-date-picker>
                </v-menu>
              </v-col> -->
            <!-- </div> -->
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn @click="DialogNuevoTramite = false" color="error">
            Cancelar
          </v-btn>
          <v-btn color="claro" dark @click="NuevoTramite()"> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <div id="app">
      <div class="blur-background" v-show="alerta"></div>
      <div class="alert-container" v-show="alerta" style="z-index: 9999;">
        <v-alert  
          type="error"
          outlined
          class="alert error-alert"
        >
          <template v-slot:icon="{ attrs }">
            <v-icon v-bind="attrs" class="custom-alert-icon">mdi-alert</v-icon>
          </template>
          <h2>{{ msj }}</h2>
          <v-btn 
            icon 
            class="close-button" 
            @click="alerta=false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-alert>
      </div>
    </div> -->
    <v-snackbar v-model="snackbarconfirm" :timeout="timeout" top>
      {{ msj }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbarconfirm = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>

  <!-- </v-container> -->
</template>

<script>
import pagardeudas from "../Escribanos/Pagar_deuda_escribano";
import formulario from "../Escribanos/Formulario_tramite";
import principal from "../Escribanos/Principal_escribanos";
export default {
  data: () => ({
    alerta: false,
    snackbarconfirm: false,
    timeout: 3000,
    msj: "",
    loading: false,
    expanded: [],
    showDetails: "",
    headersescribanos: [
      {
        text: "N° DE TRÁMITE",
        align: "start",
        value: "pktramite",
        show: false,
      },
      { text: "NOMENCLATURA", value: "nomemclatura", align: "start" },
      { text: "ESTADO", value: "estado" },
      { text: "FECHA", value: "fecha_linda" },
      {
        text: "OPCIONES",
        value: "actions",
        align: "right",
        width: 300,
        sortable: false,
      },
    ],
    escribanos: [],
    escribano: "",
    buscador: "",
    datevencimiento: new Date().toISOString().substr(0, 10),
    pkescribano: "",
    datostramite: {},
    DialogNuevoTramite: false,
    validformnuevotramite: true,
    nomenclatura: {
      circunscripcion: "",
      seccion: "",
      manzana: "",
      letra_manzana: "",
      parcela: "",
      letra_parcela: "",
      unidad_funcional: "",
      chacra: "",
      letra_chacra: "",
      quinta: "",
      fraccion: "",

      partida: "",
      sellado: "",
    },
    // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

    tipo: 1,
    // menufecha:false,
    valores: [],
    checkescri: {},
    disabled: false,
  }),

  components: {
    formulario,
    principal,
    pagardeudas,
  },

  computed: {
    ComponenteEscribano() {
      return {
        escribano: this.datevencimiento,
        disabled: this.disabled,
        checkescri: this.checkescri,
      };
    },
    componentepagardeuda() {
      return {
        vencimiento: this.datevencimiento,
        pkescribano: this.pkescribano,
        datosescribano: this.datosescribano,
      };
    },
    expandedIsActive() {
      return this.expanded.length > 0;
    },
  },

  watch: {},

  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.CheckEscribano();
      this.ListadoEscribanos();
    },

    Estado(item) {
      switch (item.fkestado) {
        case 11:
        case 12:
          return "color-red"; // rojo
        case 2:
          return "color-green"; // verde
        case 7:
        case 4:
          return "color-orange"; // naranja
        default:
          return "";
      }
    },

    Fecha(fecha) {
      if (fecha == "0000-00-00") {
        return "00-00-0000";
      } else {
        return this.moment(fecha).utc().format("DD/MM/YYYY");
      }
    },

    MotivoRechazo(motivo) {
      this.$swal.fire({
        title: "Motivo de rechazo",
        text: motivo,
      });
    },

    PruebaAlerta(mensaje) {
      this.msj = mensaje;
      this.alerta = true;
      // Desactivar la alerta después de 3 segundos
      // setTimeout(() => {
      //   this.alerta = false;
      // }, this.timeout);
    },

    CheckEscribano() {
      var obj = this;
      this.$ajax
        .get(`${this.$apitramites}escribanos/checkEscribano`)
        .then(function (response) {
          // obj.$emit('datos-recibidos', response);
          console.log("check escri", response);
          obj.checkescri = response.data;
          // obj.AbrirPrincipal();

          // if(response.data.estado==3){
          // }
          // obj.escribanos=response.data
        })
        .catch(function (error) {
          obj.snackbar = true;
          obj.msj = error;
        });
    },

    ListadoEscribanos() {
      this.loading = true;
      var obj = this;
      this.$ajax
        .get(`${this.$apitramites}escribanos/traerTramites`)
        .then(function (response) {
          console.log("Listado Escribanos", response);
          obj.escribanos = response.data;
          obj.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          obj.$swal.fire({
            title: "Error",
            text: "Sin datos cargados",
            icon: "error",
          });
        });
    },

    laclase(item) {
      if (item.condicontri != "2") {
        if (item.t_desvie == "1" && item.t_lugar == "1") {
          return { class: "green--text", restringido: false };
        } else if (
          ((item.t_desvie == "1" && item.t_lugar == "0") ||
            (item.t_desvie == "0" && item.t_lugar == "1")) &&
          item.t_web == "1"
        ) {
          return { class: "orange--text", restringido: false };
        } else {
          return { class: "red--text", restringido: true };
        }
      } else {
        return { class: "red--text", restringido: true };
      }
    },

    AbrirFormulario() {
      this.$refs["formuescri"];
    },

    TraerValores() {
      var obj = this;
      this.$ajax
        .get(`${this.$apitramites}funciones/preciosEscribanos`)
        .then(function (response) {
          console.log("valores sellado", response);
          obj.valores = response.data;
        })
        .catch(function (error) {
          obj.snackbarconfirm = true;
          obj.msj = error;
        });
    },

    NuevoTramite() {
      if (this.$refs.formnuevotramite.validate()) {
        console.log("si bebe", this.nomenclatura);
        var obj = this;
        this.$ajax({
          method: "post",
          url: `${this.$apitramites}escribanos`,
          responseType: "blob",
          data: this.nomenclatura,
        })
          .then(function (response) {
            console.log("nuevo tramite", response);
            // obj.$swal.fire({
            //     title: "¡Agregado!",
            //     text: "Trámite cargado con éxito",
            //     icon: "success"
            //   });
            obj.ImprimirPdf(response.data);
          })
          .catch(function (error) {
            obj.$swal.fire({
              title: "Error",
              text: error,
              icon: "error",
            });
          });
      }
    },

    // async ImprimirPdf(pdf){
    //   var obj = this;
    //   if (pdf) {
    //     console.log("pdf", pdf);
    //     if (pdf.type === "application/json") {
    //         const reader = new FileReader();
    //         reader.onload = function(event) {
    //             const text = event.target.result;
    //             const response = JSON.parse(text);
    //             if (response.estado) {
    //                 console.log("entra error")
    //                 this.$swal.fire({
    //                   title: "¡Atención!",
    //                   text: response.msj,
    //                   icon: "info",
    //                 });

    //             } else {
    //                 const url = URL.createObjectURL(pdf);
    //                 window.open(url);
    //             }
    //         }.bind(this);
    //         reader.readAsText(pdf);
    //     } else {
    //         const url = URL.createObjectURL(pdf);
    //         window.open(url);
    //         console.log("aca")
    //         obj.msj="Solicitud enviada con exito!!!";
    //         obj.snackbarconfirm=true;
    //         obj.DialogNuevoTramite=false;
    //         obj.$refs.formnuevotramite.reset();
    //         obj.ListadoEscribanos();
    //     }
    //   } else {
    //       console.error("No se proporcionó el PDF para imprimir.");
    //   }
    // },

    async ImprimirPdf(pdf) {
      var obj = this;
      if (pdf) {
        console.log("pdf", pdf);
        if (pdf.type === "application/json") {
          console.log("hola");
          const reader = new FileReader();
          reader.onload = function (event) {
            const text = event.target.result;
            const response = JSON.parse(text);
            if (response.estado == 2) {
              console.log("entra error");
              obj.alerta = true;
              obj.$swal
                .fire({
                  title: response.msj,
                  icon: "warning",
                  showCancelButton: true,
                  reverseButtons: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  cancelButtonText: "No, cancelar!",
                  confirmButtonText: "Sí, confirmar!",
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    obj.nomenclatura.aceptarNuevo = 1;
                    obj
                      .$ajax({
                        method: "post",
                        url: `${obj.$apitramites}escribanos`,
                        responseType: "blob",
                        data: obj.nomenclatura,
                      })
                      .then(function (response) {
                        console.log("nuevo tramite", response);
                        obj.ImprimirPdf(response.data);
                        obj.alerta = false;
                      })
                      .catch(function (error) {
                        obj.$swal.fire({
                          title: "Error",
                          text: error,
                          icon: "error",
                        });
                      });
                  } else {
                    obj.$refs.formnuevotramite.reset();
                    obj.alerta = false;
                    obj.DialogNuevoTramite = false;
                  }
                });

              // this.PruebaAlerta(response.msj)
            } else {
              // Si no hay errores, abrir el PDF en una nueva ventana
              const url = URL.createObjectURL(pdf);
              window.open(url);
            }
          }.bind(this);
          reader.readAsText(pdf);
        } else {
          // Si no es JSON, simplemente abrir el PDF en una nueva ventana
          const url = URL.createObjectURL(pdf);
          window.open(url);
          console.log("aca");
          obj.$swal.fire({
            title: "¡Exito!",
            text: "Solicitud enviada con exito.",
            icon: "success",
          });
          obj.DialogNuevoTramite = false;
          obj.$refs.formnuevotramite.reset();
          obj.ListadoEscribanos();
        }
      } else {
        console.error("No se proporcionó el PDF para imprimir.");
      }
    },

    CertificadoLiberado(item) {
      var obj = this;
      this.$ajax({
        method: "post",
        url: `${this.$apitramites}escribanos/imprimir`,
        responseType: "blob",
        data: {
          pktramite: item.pktramite,
        },
      })
        .then(function (response) {
          console.log("certificado", response);
          // obj.ImprimirPdf(response.data);
          obj.PdfCertificado(response.data);
        })
        .catch(function (error) {
          obj.$swal.fire({
            title: "Error",
            text:
              error.message ||
              "Ha ocurrido un error al generar el certificado.",
            icon: "error",
          });
        });
    },

    PdfCertificado(pdf) {
      if (pdf) {
        console.log("pdf", pdf);

        // Revisar si el tipo del contenido es JSON, lo cual podría indicar un error
        if (pdf.type === "application/json") {
          const reader = new FileReader();
          reader.onload = function (event) {
            try {
              const text = event.target.result;
              const response = JSON.parse(text);

              if (response.estado) {
                this.$swal.fire({
                  title: "¡Atención!",
                  text: response.msj,
                  icon: "info",
                });
              }
            } catch (e) {
              console.error("Error al leer el JSON:", e);
              this.$swal.fire({
                title: "Error",
                text: "No se pudo procesar la respuesta del servidor.",
                icon: "error",
              });
            }
          }.bind(this);
          reader.readAsText(pdf);
        } else {
          // Si el tipo es PDF, abrir en una nueva ventana
          console.log("aca");
          const url = URL.createObjectURL(pdf);
          window.open(url);

          // Limpiar el objeto URL para evitar fugas de memoria
          setTimeout(() => {
            URL.revokeObjectURL(url);
          }, 100);
        }
      } else {
        console.error("No se proporcionó el PDF para imprimir.");
      }
    },

    CerrarExpand() {
      this.expanded = [];
    },

    AbrirDialogDeudas() {
      console.log("ref", this.$refs);
      console.log("ref2", this.$refs["formDeuda"]);
      this.$refs["formDeuda"];
    },

    vaciarExpanded() {
      this.expanded = [];
    },
    // AbrirPrincipal(){
    //   console.log("asd1", this.$refs)
    //   this.$refs['formprincipal'];
    // }
  },
};
</script>
<style scoped>
.v-dialog-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
}
#app {
  position: relative;
}

.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(
    5px
  ); /* Ajusta el valor de desenfoque según lo necesites */
  z-index: 1;
}

.alert-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.alert {
  z-index: 2;
}

.error-alert {
  animation: shake 0.5s ease-in-out infinite alternate;
  background-color: #ffcccc !important;
  padding: 30px;
}

@keyframes shake {
  from {
    transform: translateX(-3px);
  }
  to {
    transform: translateX(3px);
  }
}

.custom-alert-icon {
  font-size: 35px; /* Puedes ajustar este valor según tu preferencia */
}
.close-button {
  position: absolute;
  top: 2px; /* Ajusta la posición vertical según tus necesidades */
  right: 2px; /* Ajusta la posición horizontal según tus necesidades */
  /* background-color: #ffffff; Cambia el color de fondo del botón si es necesario */
  /* border: 1px solid #000000; Cambia el color del borde si es necesario */
  border-radius: 50%; /* Ajusta el radio del borde para que sea circular */
  padding: 5px; /* Ajusta el espacio interior del botón según tus necesidades */
  z-index: 999; /* Ajusta el valor z-index para que esté por encima del contenido del alert */
}
.color-red {
  color: red;
}

.color-green {
  color: green;
}

.color-orange {
  color: orange;
}
</style>
